import moment from 'moment'
import { nameFromUrl } from './strFormatting'

/* Fetch helper */

function fetchApi(endPoint, processFunc) {
    return new Promise( (resolve, reject) => {
        console.log(`About to load ${endPoint}`);
        fetch(endPoint.replace(' ', '-'))
            .then(response => response.json())
            .then(data => {
                if (processFunc) {
                    data = processFunc(data)
                }
                resolve(data)
            })
            .catch(error => {
                reject(error)
            });
    });
}


/* Data prodcessing helpers */

function computeExposure(e) {
    e['exposure'] = null;
    if (e.population && e.mean_pm25) {
        e['exposure'] = +((e.population * e.mean_pm25 / 1000000).toFixed(2));
    }
}

function convertDateString(e, dateField) {
    if (e[dateField]) {
        e[dateField] = moment.utc(e[dateField]).format('YYYY-MM-DD')
    }
}

function sortByDate(data, dateField) {
    return data.sort((a, b) => {
        return a[dateField] > b[dateField] ? -1 : a[dateField] < b[dateField] ? 1 : 0;
    })
}


/* exported fetch functions */

export function fetchValidateRegion(options) {
    let endPoint = `/api/validate/region/${options.region}`;
    if (options.state) {
        endPoint += `/${options.state}`
        if (options.county) {
            endPoint += `/${options.county}`
        }
    }
    return fetchApi(endPoint)
}

export function fetchRegionReportsTabData(options) {
    let endPoint = null;
    if (options.state && options.county) {
        endPoint = `/api/county-reports-tab-data/${options.state}/${options.county}`;
    } else if (options.state) {
        endPoint = `/api/state-reports-tab-data/${options.state}`;
    } else {
        endPoint = `/api/region-reports-tab-data/${options.region}`;
    }
    console.log(`Fetching region reports tab data for ${endPoint}`)
    return fetchApi(endPoint)
}

export function fetchCountyTableData(options) {
    options = options || {};
    // There is no state or county spcific endpoints, so we need to
    // query all and filter
    // TODO: create  state and county endpoints
    let state = options.state && nameFromUrl(options.state)
    let county = options.county && nameFromUrl(options.county)
    let endPoint = '/api/county-table-data';
    if (options.state){
        endPoint = `${endPoint}/${options.state}`;
        if (options.county) {
            endPoint = `${endPoint}/${options.county}`;
        }
    }

    return fetchApi(endPoint, (data) => {
        data.forEach((e) => {
            computeExposure(e);

            // eliminate zero and negative doubling rate values
            if (e.five_day_doubling_rate <= 0) {
                e.five_day_doubling_rate = null;
            }
            convertDateString(e, 'report_date')
        })
        return data
    })
}

function fetchPM25(options) {
    options = options || {};
    let endPoint = `/api/${options.resolution}-${options.timeScale}-pm25/${options.state}`;
    if (options.county){
        endPoint = `${endPoint}/${options.county}`
    }
    return fetchApi(endPoint, (data) => {
        data.forEach((e) => {
            if (options.timeScale == 'daily') {
                computeExposure(e);
            }
            convertDateString(e, 'local_date')
        })
        let sortField = (options.timeScale == 'daily') ? ('local_date') : ('local_time')
        return sortByDate(data, sortField);
    })
}

export function fetchCountyDailyPM25(options) {
    options = options || {};
    options.timeScale = 'daily';
    options.resolution = 'county';
    return fetchPM25(options)
}
export function fetchCountyRecentPM25(options) {
    options = options || {}
    options.timeScale = 'recent';
    options.resolution = 'county';
    return fetchPM25(options)
}

export function fetchMonitorDailyPM25(options) {
    options = options || {};
    options.timeScale = 'daily';
    options.resolution = 'monitor';
    return fetchPM25(options)
}

export function fetchCountyReportLocation(options) {
    options = options || {};
    let endPoint = `/api/county-report-location/${options.state}`;
    if (options.county){
        endPoint = `${endPoint}/${options.county}`
    }
    return fetchApi(endPoint, (data) => {
        data.forEach((e) => {
            convertDateString(e, 'report_date')
        })
        // reverse sort by report_date
        return sortByDate(data, 'report_date');
    })
}

export function fetchGoes16(options) {
    options = options || {};
    let endPoint = `/api/county-daily-goes16/${options.state}`;
    if (options.county){
        endPoint = `${endPoint}/${options.county}`
    }
    return fetchApi(endPoint)
}

export function fetchCurrentMapStats() {
    let endPoint = `/api/current-map-stats`;
    return fetchApi(endPoint)
}

export function fetchCountyGeoJSON(options) {
    options = options || {};
    let endPoint = `/api/county-geojson`;
    endPoint = `${endPoint}/${options.state}/${options.county}`
    return fetchApi(endPoint)
}

export function fetchCountiesWithStates() {
    let endPoint = '/api/counties-with-state';
    return fetchApi(endPoint)
}

export function fetchRegions() {
    let endPoint = '/api/regions';
    return fetchApi(endPoint)
}

export function fetchRegionalCountiesList(options) {
    let endPoint = `/api/counties-list/region/${options.region}`;
    return fetchApi(endPoint)
}

export function fetchRegionCountyData(options) {
    let endPoint = `/api/region-county-data/region/${options.region}`;
    return fetchApi(endPoint)
}

export function fetchRegionAqiPercentages(options) {
    let endPoint = `/api/region-aqi-percentages/region/${options.region}`;
    return fetchApi(endPoint)
}

export function fetchRegionCovidData(options) {
    let endPoint = `/api/region-covid-data/region/${options.region}`;
    return fetchApi(endPoint)
}

export function fetchHpStateData() {
    let endPoint = '/api/hp-state-data';
    return fetchApi(endPoint)
}

export function fetchCountyModelProjections(options) {
    let endPoint = `/api/county-model-projections/${options.state}/${options.county}`;
    return fetchApi(endPoint)
}

export function fetchCountyCovidIntervalData(options) {
    let endPoint = `/api/county-covid-interval-data/${options.state}/${options.county}`;
    return fetchApi(endPoint)
}

export function fetchCountyCovidData(options) {
    let endPoint = `/api/county-covid-data/${options.state}/${options.county}`;
    return fetchApi(endPoint)
}

export function fetchCountyStaticData(options) {
    let endPoint = `/api/county-static-data/${options.state}/${options.county}`;
    return fetchApi(endPoint)
}

export function fetchCountyAqiPercentages(options) {
    let endPoint = `/api/county-aqi-percentages/${options.state}/${options.county}`;
    return fetchApi(endPoint)
}

export function fetchCountyFireData(options) {
    let endPoint = `/api/county-fire-data/${options.state}/${options.county}`;
    return fetchApi(endPoint)
}

export function fetchCountyMonitorProximity(options) {
    let endPoint = `/api/monitor-population-coverage-percentage/${options.state}/${options.county}`;
    return fetchApi(endPoint)
}

export function fetchStateGeojson(options) {
    return fetchApi(`/api/state-geojson/${options.state}`);
}

export function fetchRegionGeojson(options) {
    return fetchApi(`/api/region-geojson/region/${options.region}`);
}

export function fetchStateCovidIntervalData(options) {
    return fetchApi(`/api/state-covid-interval-data/${options.state}`);
}

export function fetchStateCovidData(options) {
    return fetchApi(`/api/state-covid-data/${options.state}`);
}

export function fetchStateStaticData(options) {
    return fetchApi(`/api/state-static-data/${options.state}`);
}

export function fetchStateDailySmoke(options) {
    return fetchApi(`/api/state-daily-smoke/${options.state}`);
}

export function fetchRegionDailySmoke(options) {
    return fetchApi(`/api/region-daily-smoke/region/${options.region}`);
}

export function fetchStateDailyPM25(options) {
    return fetchApi(`/api/state-daily-pm25/${options.state}`);
}

export function fetchStateAqiPercentages(options) {
    let endPoint =  `/api/state-aqi-percentages/${options.state}`
    return fetchApi(endPoint, (data) => {
            data.forEach((e) => {
                e.local_date_iso = e.local_date
                convertDateString(e, 'local_date_iso')
            })
            return sortByDate(data, 'local_date_iso');
        });
}

export function fetchCountyBorders(options) {
    return fetchApi('https://airfire-data-exports.s3-us-west-2.amazonaws.com/covid/geojson/county-borders.geojson')
}

export function fetchHMSData(options) {
    return fetchApi('https://airfire-data-exports.s3-us-west-2.amazonaws.com/covid/geojson/hms-smoke.geojson')
}

export function fetchGOESData(options) {
    return fetchApi('https://airfire-data-exports.s3-us-west-2.amazonaws.com/covid/geojson/goes16-detects.geojson')
}

export function fetchCovidCountyGeojson(options) {
    return fetchApi('https://airfire-data-exports.s3-us-west-2.amazonaws.com/covid/geojson/covid-county.geojson')
}
