import { observable, computed, action, decorate } from 'mobx';
import moment from 'moment'
import {
    fetchValidateRegion,
    fetchRegionalCountiesList,
    fetchRegions,
    fetchRegionCountyData,
    fetchRegionAqiPercentages,
    fetchRegionCovidData,
    fetchCountyTableData,
    fetchCountyMonitorProximity,
    fetchCountyModelProjections,
    fetchCountyCovidIntervalData,
    fetchCountyCovidData,
    fetchCountyStaticData, fetchCountyAqiPercentages,
    fetchCountyFireData,
    fetchCountyDailyPM25, fetchCountyRecentPM25,
    fetchMonitorDailyPM25,
    fetchCountyReportLocation, fetchGoes16,
    fetchCurrentMapStats, fetchCountiesWithStates,
    fetchStateGeojson, fetchCountyGeoJSON, fetchRegionGeojson,
    fetchStateCovidIntervalData, fetchStateDailyPM25,
    fetchStateCovidData, fetchStateStaticData,
    fetchStateAqiPercentages, fetchStateDailySmoke,
    fetchCountyBorders,
    fetchHMSData, fetchHpStateData,
    fetchGOESData,
    fetchCovidCountyGeojson,
    fetchRegionReportsTabData,
    fetchRegionDailySmoke,
    fetchStateReportsTabData,
    fetchCountyReportsTabData
} from '../lib/apiUtils'

// TODO: get decorators working
//   See https://mobx.js.org/best/decorators.html

class SessionStorageWithExpiration {

    constructor(ttlMinutes, keyPrefix) {
        ttlMinutes = ttlMinutes || 15;
        // ttl needs to be in milliseconds
        this.ttl = ttlMinutes * 1000 * 60;
        // keyPrefix avoids collisions, which can happen when
        // when different endpoints have same parameters
        this.keyPrefix = keyPrefix;
    }

    cacheKey(key) {
        return this.keyPrefix + key;
    }

    setItem(key, obj) {
        console.log(`Setting sessionStorage.${this.cacheKey(key)}`)
        let exp = moment.now() + this.ttl
        try {
            sessionStorage.setItem(this.cacheKey(key), JSON.stringify({exp, obj}))
        } catch(e){
            console.log("Failed to set item in sessionStorage")
        }
    }

    getItem(key) {
        let storeObj = sessionStorage.getItem(this.cacheKey(key))
        if (storeObj){
            storeObj = JSON.parse(storeObj);
            let n = moment.now()
            if (storeObj.exp > moment.now()) {
                console.log(`Using sessionStorage.${this.cacheKey(key)}`)
                return storeObj.obj
            } else {
                console.log(`Removing expired sessionStorage.${this.cacheKey(key)}`)
                sessionStorage.removeItem(this.cacheKey(key))
            }
        }
        // else returns undefined
    }
}

class ApiStore {
    data = null;
    fetching = false; // used for 'loading...' in UI

    constructor(fetchFun, ttlMinutes) {
        this.fetchFunc = fetchFun
        this.sessionStorage = new SessionStorageWithExpiration(
            ttlMinutes, this.fetchFunc.name)
    }

    getDataKey(options) {
        // returns unique key for this api fetch
        // TODO: hash this json string?
        return JSON.stringify(options)
    }

    fetch(options) {
        options = options || {}

        // TODO: implement sessionStorage expiration/ttl
        // TODO: move session storage caching of api data to
        //   apiUtils, which will prevent redundant parsing an
        //   stringifying of api data

        // first clear any data that was fetched for previous page
        this.data = null;

        this.previousPage = window.location.pathname;
        let key = this.getDataKey(options)
        let cachedData = this.sessionStorage.getItem(key);
        if (cachedData) {
            this.data = cachedData
        } else {
            this.fetching = true
            this.fetchFunc(options)
                .then((data) => {
                    this.data = data
                    this.fetching = false
                    this.sessionStorage.setItem(key, data);
                })
                .catch((e) => {
                    console.log("Error fetching data: " + e)
                    this.fetching = false
                    // TODO: trigger observers of this.data to act,
                    //   even though this.data hasn't changed value
                })
        }
    }
}
decorate(ApiStore, {
  data: observable,
  fetching: observable,
  fetch: action
});

export const validateRegionStore = new ApiStore(fetchValidateRegion);
export const regionReportsTabDataStore = new ApiStore(fetchRegionReportsTabData);
export const regionsStore = new ApiStore(fetchRegions);
export const regionCountyDataStore = new ApiStore(fetchRegionCountyData);
export const regionAqiPercentagesStore = new ApiStore(fetchRegionAqiPercentages);
export const regionCovidDataStore = new ApiStore(fetchRegionCovidData);
export const regionDailySmokeStore = new ApiStore(fetchRegionDailySmoke);
export const countyTableDataStore = new ApiStore(fetchCountyTableData, 60);
export const countyRecentPM25Store = new ApiStore(fetchCountyRecentPM25);
export const countyDailyPM25Store = new ApiStore(fetchCountyDailyPM25);
export const monitorDailyPM25Store = new ApiStore(fetchMonitorDailyPM25);
export const countyReportLocationStore = new ApiStore(fetchCountyReportLocation);
export const goes16Store = new ApiStore(fetchGoes16);
export const currentMapStatsStore = new ApiStore(fetchCurrentMapStats);
export const countyMonitorProximityStore = new ApiStore(fetchCountyMonitorProximity);
export const countyModelProjectionsStore = new ApiStore(fetchCountyModelProjections);
export const countyCovidIntervalDataStore = new ApiStore(fetchCountyCovidIntervalData);
export const countyCovidDataStore = new ApiStore(fetchCountyCovidData);
export const countyStaticDataStore = new ApiStore(fetchCountyStaticData);
export const countyAqiPercentagesStore = new ApiStore(fetchCountyAqiPercentages);
export const countyFireDataStore = new ApiStore(fetchCountyFireData);
export const stateGeojsonStore = new ApiStore(fetchStateGeojson);
export const regionGeojsonStore = new ApiStore(fetchRegionGeojson);
export const countyGeojsonStore = new ApiStore(fetchCountyGeoJSON);
export const stateCovidIntervalDataStore = new ApiStore(fetchStateCovidIntervalData);
export const stateDailyPM25Store = new ApiStore(fetchStateDailyPM25);
export const stateCovidDataStore = new ApiStore(fetchStateCovidData);
export const stateStaticDataStore = new ApiStore(fetchStateStaticData);
export const stateDailySmokeStore = new ApiStore(fetchStateDailySmoke);
export const stateAqiPercentagesStore = new ApiStore(fetchStateAqiPercentages);
export const countyBordersStore = new ApiStore(fetchCountyBorders);
export const hmsDataStore = new ApiStore(fetchHMSData);
export const goesDataStore = new ApiStore(fetchGOESData);
export const hpStateDataStore = new ApiStore(fetchHpStateData);
export const covidCountyGeojsonStore = new ApiStore(fetchCovidCountyGeojson);

class CountiesWithStateStore extends ApiStore {

    get states() {
        if (this.data) {
            return [...new Set( this.data.map((e) => {return e.state}) )].sort()
        }
        /* explicitly return null to avoid console error message */
        return null;
    }

    counties(state) {
        if (this.data) {
            return this.data.reduce((r, e) => {
                if (e.state === state) {
                    r.push(e.county);
                }
                return r;
            }, []).sort()
        }
        /* explicitly return null to avoid console error message */
        return null;
    }
}

decorate(CountiesWithStateStore, {
  data: observable,
  states: computed
});

export const countiesWithStateStore = new CountiesWithStateStore(fetchCountiesWithStates)
export const regionCountyListStore = new CountiesWithStateStore(fetchRegionalCountiesList);
