export function roundAndFormat(val, options) {
    options = options || {}
    let decimalPlace = options.decimalPlace || 0.1;
    if (val || val == 0) {
        try {
            if (Math.abs(val) >= decimalPlace) {
                val = (!options.noFractionForLargerVals || Math.abs(val) < 10)
                    ? (Math.round(val / decimalPlace) * decimalPlace)
                    : Math.round(val);
            } else {
                val = Number.parseFloat(val).toPrecision(1)
            }
            let plusSign = (val > 0 && options.showPlusSign) ? '+' : '';
            return plusSign + val.toLocaleString();
        } catch(e) {
            console.log(`Failed to round ${val} to ${decimalPlace} decimal point`)
            return val;
        }
    }
}


export function nameFromUrl(val) {
    /* e.g. convert 'pacific-northwest' to 'Pacific Northwest'

    Note that this can not restore names like 'mcgraw' -> 'McGraw'
    */
    return val && val.split('-').map(function(t){
        return t.replace(/^\w/, (c) => c.toUpperCase());
    }).join(' ')
}