import React from "react";
import { observer } from "mobx-react";
import Alert from 'react-bootstrap/Alert'

import * as stores from '../store/apiStores'
import './LoadingBanner.css'

export default observer(function LoadingBanner(props) {
    let isLoading = Object.values(stores).reduce((r, e) => {
        return e.fetching || r;
    }, false)

    if (isLoading) {
        return (
            <div className="data-store-loading-banner">
                <Alert variant="warning">Loading...</Alert>
            </div>
        )
    } else {
        return null
    }

})
