import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import Layout from './components/Layout'
import spinnerImg from './images/dotdotdot.gif'

const RootHomePage = lazy(() => import('./routes/RootHomePage'));
const CountyPage = lazy(() => import('./routes/CountyPage'));
const StatePage = lazy(() => import('./routes/StatePage'));
const RegionPage = lazy(() => import('./routes/RegionPage'));
const RegionAboutPage = lazy(() => import('./routes/RegionAboutPage'));
const AirQualityPage = lazy(() => import('./routes/AirQualityPage'));
const AirQualityHomePage = lazy(() => import('./routes/AirQualityHomePage'));
const AirQualityAboutPage = lazy(() => import('./routes/AirQualityAboutPage'));
const CovidPage = lazy(() => import('./routes/CovidPage'));
const CovidAboutPage = lazy(() => import('./routes/CovidAboutPage'));
const TrackingHomePage = lazy(() => import('./routes/TrackingHomePage'));
const LandingPage = lazy(() => import('./routes/LandingPage'));
const TablePage = lazy(() => import('./routes/TablePage'));
const MapPage = lazy(() => import('./routes/MapPage'));
const AboutPage = lazy(() => import('./routes/AboutPage'));
const TrackingRefCovidPage = lazy(() => import('./routes/TrackingRefCovidPage'));
const TrackingRefAQPage = lazy(() => import('./routes/TrackingRefAQPage'));
const TrackingRefFirePage = lazy(() => import('./routes/TrackingRefFirePage'));
const FourOhFourPage = lazy(() => import('./routes/FourOhFourPage'));

function Fallback(){
  return (
    <div className="fallback-spinner">
      <img src={spinnerImg} width="100" alt="Loading..." />
    </div>
  )
}

export default function App() {
  return (
    <Router>
      <Layout>
        <div>
          <Suspense fallback={<Fallback />}>
            <Switch>
                  <Route exact path='/tracking/ref/covid' children={<TrackingRefCovidPage />} />
                  <Route exact path='/tracking/ref/aq' children={<TrackingRefAQPage />} />
                  <Route exact path='/tracking/ref/fire' children={<TrackingRefFirePage />} />
                  {/*
                    TODO: eventually remove these redirects; the region
                      reoutes where never public
                  */}
                  <Redirect from='/region/home' to="/tracking/region" />
                  <Redirect from='/region/:region' to="/tracking/region/:region" />
                  <Redirect from='/region/' to="/tracking/region" />
                  <Redirect exact from='/tracking/' to="/tracking/region" />

                  <Route exact path='/tracking/region' children={<TrackingHomePage />} />
                  <Route exact path='/tracking/region/about/' children={<RegionAboutPage />} />
                  <Route exact path='/tracking/region/:region/:state/:county' children={<RegionPage />} />
                  <Route exact path='/tracking/region/:region/:state' children={<RegionPage />} />
                  <Route exact path='/tracking/region/:region' children={<RegionPage />} />

                  <Route exact path='/tracking/air-quality' children={<AirQualityHomePage />} />
                  <Route exact path='/tracking/air-quality/about/' children={<AirQualityAboutPage />} />
                  <Route exact path='/tracking/air-quality/:region/:state/:county' children={<AirQualityPage />} />
                  <Route exact path='/tracking/air-quality/:region/:state' children={<AirQualityPage />} />
                  <Route exact path='/tracking/air-quality/:region' children={<AirQualityPage />} />

                  <Route exact path='/tracking/covid/about' children={<CovidAboutPage />} />
                  <Route exact path='/tracking/covid/:state/:county' children={<CovidPage />} />
                  <Route exact path='/tracking/covid/:state' children={<CovidPage />} />
                  <Route exact path='/tracking/covid' children={<CovidPage />} />

                  <Redirect from='/tracking/region/' to="/tracking" />

                  {/* Old dashboard */}
                  <Route exact path='/dashboard/map' children={<MapPage />} />
                  <Route exact path='/dashboard/table' children={<TablePage />} />
                  <Route exact path='/dashboard/about' children={<AboutPage />} />
                  <Route exact path='/dashboard/:state/:county' children={<CountyPage />} />
                  <Route exact path='/dashboard/:state' children={<StatePage />} />
                  <Route exact path='/dashboard/' children={<LandingPage />} />

                  <Route exact path='/' children={<RootHomePage />} />

                  <Route path="*" children={<FourOhFourPage />} />
            </Switch>
          </Suspense>
        </div>
      </Layout>
    </Router>
  );
}


